class PureGallery {
    constructor(element) {
        this.gallery = element;
        this.mainImages = this.gallery.querySelectorAll('.gallery-main img');
        this.thumbs = this.gallery.querySelectorAll('.gallery-thumbs button');
        this.prevBtn = this.gallery.querySelector('.prev');
        this.nextBtn = this.gallery.querySelector('.next');
        this.currentIndex = 0;
        this.autoplayInterval = null;
        this.autoplayDelay = 3000; // 3 seconds
        this.fullscreenBtn = this.gallery.querySelector('.fullscreen-button');
        this.isFullscreen = false;

        this.setupEventListeners();
        this.startAutoplay();
        this.setupTouchSupport();
        this.updateGalleryRatio();
    }

    setupEventListeners() {
        this.thumbs.forEach((thumb, index) => {
            thumb.addEventListener('click', () => this.showImage(index));
        });

        this.prevBtn.addEventListener('click', () => this.prev());
        this.nextBtn.addEventListener('click', () => this.next());

        // Pause autoplay on hover
        this.gallery.addEventListener('mouseenter', () => this.stopAutoplay());
        this.gallery.addEventListener('mouseleave', () => this.startAutoplay());

        this.fullscreenBtn.addEventListener('click', () => this.toggleFullscreen());
        
        // Handle ESC key to exit fullscreen
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && this.isFullscreen) {
                this.exitFullscreen();
            }
        });
    }

    setupTouchSupport() {
        let touchStartX = 0;
        let touchEndX = 0;

        this.gallery.addEventListener('touchstart', (e) => {
            touchStartX = e.changedTouches[0].screenX;
        }, false);

        this.gallery.addEventListener('touchend', (e) => {
            touchEndX = e.changedTouches[0].screenX;
            this.handleSwipe(touchStartX, touchEndX);
        }, false);
    }

    handleSwipe(startX, endX) {
        const diff = startX - endX;
        if (Math.abs(diff) > 50) { // Minimum swipe distance
            if (diff > 0) {
                this.next();
            } else {
                this.prev();
            }
        }
    }

    showImage(index) {
        this.mainImages.forEach(img => img.classList.remove('active'));
        this.thumbs.forEach(thumb => thumb.classList.remove('active'));
        
        this.mainImages[index].classList.add('active');
        this.thumbs[index].classList.add('active');
        this.currentIndex = index;

        if (this.isFullscreen) {
            this.updateGalleryRatio();
        }
    }

    next() {
        const nextIndex = (this.currentIndex + 1) % this.mainImages.length;
        this.showImage(nextIndex);
    }

    prev() {
        const prevIndex = (this.currentIndex - 1 + this.mainImages.length) % this.mainImages.length;
        this.showImage(prevIndex);
    }

    startAutoplay() {
        if (this.autoplayInterval) return;
        this.autoplayInterval = setInterval(() => this.next(), this.autoplayDelay);
    }

    stopAutoplay() {
        if (this.autoplayInterval) {
            clearInterval(this.autoplayInterval);
            this.autoplayInterval = null;
        }
    }

    updateGalleryRatio() {
        const activeImage = this.mainImages[this.currentIndex];
        
        if (activeImage) {
            if (this.isFullscreen) {
                const galleryMain = this.gallery.querySelector('.gallery-main');
                galleryMain.style.paddingBottom = '0';
                
                if (activeImage.complete) {
                    this.setFullscreenImageSize(activeImage);
                } else {
                    activeImage.onload = () => this.setFullscreenImageSize(activeImage);
                }
            } else {
                if (activeImage.complete) {
                    this.setGalleryRatio(activeImage);
                } else {
                    activeImage.onload = () => this.setGalleryRatio(activeImage);
                }
            }
        }
    }

    setGalleryRatio(image) {
        const ratio = (image.naturalHeight / image.naturalWidth) * 100;
        this.gallery.querySelector('.gallery-main').style.paddingBottom = `${ratio}%`;
    }

    setFullscreenImageSize(image) {
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const imageRatio = image.naturalHeight / image.naturalWidth;
        const viewportRatio = viewportHeight / viewportWidth;

        if (imageRatio > viewportRatio) {
            image.style.height = '90vh';
            image.style.width = 'auto';
        } else {
            image.style.width = '90vw';
            image.style.height = 'auto';
        }
    }

    toggleFullscreen() {
        if (this.isFullscreen) {
            this.exitFullscreen();
        } else {
            this.enterFullscreen();
        }
    }

    enterFullscreen() {
        this.gallery.classList.add('fullscreen');
        document.body.classList.add('gallery-fullscreen');
        this.isFullscreen = true;
        this.fullscreenBtn.querySelector('i').classList.remove('bi-fullscreen');
        this.fullscreenBtn.querySelector('i').classList.add('bi-fullscreen-exit');
        this.updateGalleryRatio();
    }

    exitFullscreen() {
        this.gallery.classList.remove('fullscreen');
        document.body.classList.remove('gallery-fullscreen');
        this.isFullscreen = false;
        this.fullscreenBtn.querySelector('i').classList.remove('bi-fullscreen-exit');
        this.fullscreenBtn.querySelector('i').classList.add('bi-fullscreen');
        this.updateGalleryRatio();
    }
}

// Initialize galleries when DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.pure-gallery').forEach(gallery => {
        new PureGallery(gallery);
    });
}); 